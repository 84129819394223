


















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Post } from '~/themes/base/common/types'
import Swiper from '~components/utils/Swiper.vue'
import SwiperSlide from '~components/utils/SwiperSlide.vue'

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
})
export default class ArticleCarouselWidget extends Vue {
  @Prop({ type: String, default: '' }) readonly title!: string
  @Prop({ type: Object, default: null })
  readonly initialData!: { search: { nodes: Post[] } }

  get posts() {
    if (this.initialData && this.initialData.search?.nodes?.length) {
      return this.initialData.search.nodes
    }
    return []
  }
}
