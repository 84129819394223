<template>
  <div ref="swiper" class="swiper-container swiper-custom">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>

    <div v-if="withPagination" ref="pagination" class="swiper-pagination"></div>

    <div ref="prev" class="swiper-button-prev">
      <slot name="swiper-prev">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343l-4.95 4.95z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343l-4.95 4.95z"
          />
        </svg>
      </slot>
    </div>
    <div ref="next" class="swiper-button-next">
      <slot name="swiper-next">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.657 10L8 15.657l-1.414-1.414L10.828 10 6.58612 5.75713 8 4.343 13.657 10z"
          />
        </svg>
      </slot>
    </div>
  </div>
</template>

<script>
import Swpr, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

export default {
  props: {
    withPagination: {
      type: [Boolean, String],
      default: 'fraction',
    },
  },
  data() {
    return {
      swiper: undefined,
    }
  },
  mounted() {
    Swpr.use([Navigation, Pagination])
    this.swiper = new Swpr(this.$refs.swiper, {
      loop: true,

      pagination: this.withPagination
        ? {
            el: this.$refs.pagination,
            type: this.withPagination,
          }
        : {},

      navigation: {
        nextEl: this.$refs.next,
        prevEl: this.$refs.prev,
      },

      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    })
  },
}
</script>

<style>
@import '~components/utils/Swiper.css';
</style>
